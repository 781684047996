import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";

const NotFoundPage = () => (
  <div>
    <SEO title="Not found | Sioslife" description="" keywords="" />
    <Layout>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
    </Layout>
  </div>
);

export default NotFoundPage;
